<template>
  <div>
    <RelatorioRetrato>
      <b-row>
        <b-table
          striped
          small
          class="ExportarPDFParcela mt-3 custom-header-style"
          style="text-align: center"
          id="ExportarPDFParcelaId"
          :head-variant="'light'"
          :items="parametro.dados"
          :fields="parametro.colunas"
        >
        </b-table>
      </b-row>

      <div v-if="parametro.totalizadores">
        <div class="m-0 p-0 mt-1 ml-2">
          <p class="p-0 m-0" style="font-size: 0.8em">Valor total: {{ formatarValor(parametro.totalizadores.valorTotal) }}</p>
        </div>
      </div>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'
  import RelatorioPaisagem from '@/views/pages/relatorio/relatorioPaisagem.vue'

  export default {
    components: {
      RelatorioRetrato,
      RelatorioPaisagem,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {
        type: Object,
        required: true,
        default: () => ({
          totalizadores: {
            valorTotal: 0,
          },
          dados: [],
          colunas: [],
        }),
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },
  }
</script>
