<template>
  <div>
    <!-- Cabeçalho -->
    <b-card class="card card-congratulations">
      <h1 class="mb-2 mt-50 text-white">Quitação do Contrato: {{ this.parametro.id }}</h1>
      <b-card-text class="m-auto">
        <b-row>
          <b-col md="4" class="mb-1"> <strong>Tomador: </strong>{{ this.parametro.proposta.tomador.nomeCompleto }} </b-col>
          <b-col md="4" class="mb-1"> <strong>Matrícula: </strong>{{ this.parametro.proposta.tomador.matricula }} </b-col>
          <b-col md="4" class="mb-1"> <strong>Data de Inicio: </strong>{{ formatarData(this.parametro.dtInicio) }} </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="mb-1">
            <strong>Quantidade de Parcelas Totais: </strong>{{ this.parametro.numeroParcelas }}
          </b-col>
          <b-col md="8" class="mb-1"> <strong>Observações sobre o contrato: </strong>{{ this.parametro.observacao }} </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="mb-1"> <strong>Quantidade de Parcelas Pagas: </strong>{{ this.totalParcelaPag }} </b-col>
          <b-col md="4" class="mb-1">
            <strong>Valor Pago: </strong>
            {{ this.valorTotalPago }}
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="mb-1"> <strong>Quantidade de Parcelas Restantes: </strong>{{ this.totalRows }} </b-col>
          <b-col md="4" class="mb-1">
            <strong>Valor para quitação: </strong>
            {{ this.valorTotalRestante }}
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" :hidden="this.loading">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="margin-top: 20px"
              variant="success"
              @click.prevent="authESalve"
              :disabled="loading"
            >
              {{ !loading ? 'Confirmar quitação' : '' }}
            </b-button>
          </b-col>
          <b-col md="12" :hidden="!this.loading">
            <b-card bg-variant="success" class="text-center">
              <b-spinner small v-if="loading" />
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <!-- Títutlo Tabela -->
    <b-card-text>
      <h2 class="text-black mb-2 pt-2">Detalhes das parcelas</h2>
    </b-card-text>

    <b-card border-variant="primary" v-show="this.totalRows === 0">
      <h3 class="text-black mb-2">Não há parcelas disponíveis para este contrato</h3>
      <b-card-text class="text-black mb-5"> Nenhuma parcela foi encontrada no cadastrado deste contrato. </b-card-text>
    </b-card>

    <!-- Tabela -->
    <b-table
      v-show="this.totalRows"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :current-page="pesquisar.currentPage"
      :items="tabela"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template #cell(parcela)="row">
        {{ row.item.parcela }}
      </template>
      <template #cell(valor)="row">
        {{ row.item.valor }}
      </template>
      <template #cell(taxa)="row">
        {{ row.item.taxa }}
      </template>
      <template #cell(encargo)="row">
        {{ row.item.encargo }}
      </template>
      <template #cell(statusLancamento)="finanrow">
        <b-badge pill :variant="statusLancamento[0][finanrow.item.statusLancamento]">
          {{ finanrow.item.statusLancamento == 'Lancado' ? 'Lançado' : 'Não Lançado' }}
        </b-badge>
      </template>
      <template #cell(statusGRPE)="finanrow">
        <b-badge pill :variant="statusGRPE[0][finanrow.item.statusGRPE]">
          {{ finanrow.item.statusGRPE == 'GeradoGRPE' ? 'Arrecadação foi gerada' : 'Arrecadação não foi gerada' }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import vSelect from 'vue-select'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { formatarValor, formatarData } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import moment from 'moment'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      vSelect,
      VueAutosuggest,
      AutenticaSenha,
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
        loading: false,
        number: {},
        dados: {},
        suggestions: [],
        timeout: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 6, currentPage: 1 },
        striped: true,

        //Campos tabela
        fields: [
          { key: 'parcela', label: 'Nº da Parcela', sortable: true },
          { key: 'valor', label: 'Valor', sortable: true },
          { key: 'taxa', label: 'Taxa' },
          { key: 'encargo', label: 'Encargo' },
          { key: 'statusLancamento', sortable: true },
          { key: 'statusGRPE', sortable: true },
        ],
        tabela: [],
        totalParcelaPag: [],
        valorTotalRestante: [],
        valorTotalRestanteRaw: [],
        valorTotalPago: [],
        parcelasRestantes: [],
        statusGRPE: [
          {
            GeradoGRPE: 'success',
            NaoGeradoGRPE: 'warning',
            null: 'warning',
          },
        ],
        statusLancamento: [
          {
            Lancado: 'success',
            null: 'warning',
            NaoLancado: 'warning',
          },
        ],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        useJwt
          .pesquisar(`financeiro/GetbuscaParcelas/${this.parametro.id}`)
          .then((response) => {
            var parcelaRest = []
            var contRest = 0
            var parcelaPag = []
            var contPag = 0
            var mostrarTotalRestante = 0
            var mostrarTotalRestanteSJuros = 0
            var vencimento = moment(new Date(), 'YYYY-MM-DD').format('MM/YYYY')
            const today = moment(new Date(), 'YYYY-MM-DD').add(1, 'M').format('MM-YYYY')
            response.data.map((parc) => {
              if (parc.statusGRPE != 'GeradoGRPE') {
                parcelaRest[contRest] = parc
                contRest++
              } else if (parc.statusGRPE == 'GeradoGRPE') {
                parcelaPag[contPag] = parc
                contPag++
              }
            })
            this.tabela = response.data
            this.totalRows = parcelaRest.length
            this.totalParcelaPag = parcelaPag.length
            this.parcelasRestantes = parcelaRest

            parcelaRest.map((response) => {
              vencimento = moment(response.vencimento, 'YYYY-MM-DD').format('MM-YYYY')

              if (vencimento == today)
                mostrarTotalRestanteSJuros =
                  mostrarTotalRestanteSJuros +
                  (parseFloat(response.taxa) / 100 - 1) *
                    parseFloat(response.valor.replace(/[.]/g, '').replace(/[,]/g, '.')) *
                    -1
              else if (today < vencimento)
                mostrarTotalRestante =
                  mostrarTotalRestante + parseFloat(response.valor.replace(/[.]/g, '').replace(/[,]/g, '.'))
              else if (vencimento < today)
                mostrarTotalRestante =
                  mostrarTotalRestante + parseFloat(response.valor.replace(/[.]/g, '').replace(/[,]/g, '.'))
            })

            this.valorTotalRestanteRaw = mostrarTotalRestante + mostrarTotalRestanteSJuros
            this.valorTotalRestante = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
              this.valorTotalRestanteRaw,
            )

            var mostrarTotalPago = 0
            parcelaPag.map((response) => {
              mostrarTotalPago = mostrarTotalPago + parseFloat(response.valor.replace(/[.]/g, '').replace(/[,]/g, '.'))
            })
            this.valorTotalPago = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
              mostrarTotalPago,
            )
          })
          .catch((error) => {
            console.error(error)
          })
      },
      authESalve() {
        this.loading = true
        this.dados.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.dados.mode == 'authESalve') {
            this.salvar()
          }
        } else {
          this.loading = false
        }
      },
      salvar() {
        this.$swal({
          title: 'Atenção!',
          text: 'Realmente desejar realizar a QUITAÇÃO deste contrato?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var grpe = []
            var g = 0
            this.parcelasRestantes.map((pr) => {
              grpe[g] = pr.id
              g++
            })
            if (grpe.length > 0) {
              var varPesquisar = {
                ...this.pesquisar,
                institutoSelecionado: this.userData.institutoSelecionado,
              }
              useJwt
                .put(`financeiro/putQuitacao`, {
                  idParcelaId: grpe,
                  pesquisar: varPesquisar,
                  dados: {
                    responsavel: this.parametro.proposta.tomador.nomeCompleto,
                    idResponsavel: this.parametro.proposta.tomador.id,
                    qtdTomador: 1,
                    qtdContratos: 1,
                    financeiroId: this.parametro.id,
                    valorGrpe: `${this.valorTotalRestanteRaw}`,
                    valorTotal: `${this.valorTotalRestanteRaw}`,
                  },
                })
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Arrecadação da quitação foi gerada com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    this.loading = !this.loading
                    this.$emit('quitarEvent', true)
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = false
                  this.$swal({
                    title: 'Error!',
                    text: 'Ocorreu um erro ao realizar a geração do Arrecadação da quitação, tente novamente.',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    this.$emit('quitarEvent', false)
                  })
                })
            } else {
              this.loading = false
              this.$swal({
                title: 'Error!',
                text: 'Ocorreu um erro ao realizar a geração da Arrecadação da quitação, tente novamente.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                this.$emit('quitarEvent', false)
              })
            }
          } else {
            this.loading = false
            this.$swal({
              title: 'Error!',
              text: 'Ocorreu um erro ao realizar a geração do Arrecadação da quitação, tente novamente.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((result) => {
              this.$emit('quitarEvent', false)
            })
          }
        })
      },
    },
  }
</script>

<style lang="scss"></style>
