<template>
  <div>
    <b-table responsive striped :items="historicoImportacao" :fields="fields" :busy="isBusy">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template v-slot:cell(usuario)="{ item }">
        {{ item.usuario.nome }}
      </template>

      <template v-slot:cell(status)="{ item }">
        <b-badge :variant="item.status === 'Enviado' ? 'success' : 'danger'">
          {{ item.status }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { formatarValor } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    components: {},
    props: {
      parametro: {
        type: Object,
      },
    },
    directives: {
      Ripple,
    },
    mounted() {
      this.carregarGrid()
    },
    data() {
      return {
        isBusy: false,
        formatarValor,
        fields: [
          { key: 'numeroContrato', label: 'Nº do Contrato' },
          { key: 'numeroParcela', label: 'Nº da Parcela' },
          { key: 'cpf', label: 'CPF' },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          { key: 'status', label: 'Status' },
          { key: 'mensagem', label: 'Mensagem' },
        ],
        historicoImportacao: [],
      }
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        const parametro = {
          loteId: this.parametro.loteId,
        }
        useJwt
          .post('lancamento/GetParcelasDoLote', parametro)
          .then((response) => {
            if (!response.data.length) {
              this.$message.info('Nenhum registro encontrado')
            } else {
              this.historicoImportacao = response.data
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao carregar a lista de parcelas')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>

<style scoped></style>
