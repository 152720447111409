<template>
  <div>
    <b-table responsive striped :items="historicoImportacao" :fields="fields" :busy="isBusy">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="visualizar(row.item.id)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Visualizar</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Modal Lista de Parcelas Histórico -->
    <b-modal
      id="modal-lista-parcelas"
      ref="modal-lista-parcelas"
      title="Lista de Parcelas Enviadas"
      centered
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <ModalListaParcelasHistorico :parametro="parametroListaParcelas" />
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import ModalListaParcelasHistorico from './ModalListaParcelasHistorico.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarValor, formatarData } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    components: {
      ModalListaParcelasHistorico,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        type: Object,
      },
    },
    mounted() {
      this.carregarGrid()
    },
    data() {
      return {
        // Functions
        formatarValor,
        formatarData,

        isBusy: false,
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'data', label: 'Data', formatter: (value) => (value ? formatarData(value) : '') },
          { key: 'usuario', label: 'Usuário' },
          { key: 'quantidade', label: 'Quantidade', formatter: (value) => (value ? Number(value) : Number(0)) },
        ],
        historicoImportacao: [
          {
            id: 1,
            data: '01/01/2021',
            usuario: {
              nome: 'Usuário 1',
            },
            quantidade: 10,
            valorTotal: 1000,
            status: 'SUCESSO',
          },
          {
            id: 2,
            data: '02/01/2021',
            usuario: {
              nome: 'Usuário 2',
            },
            quantidade: 20,
            valorTotal: 2000,
            status: 'ERRO',
          },
        ],
        parametroListaParcelas: {
          loteId: null,
        },
      }
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        const parametro = {
          competencia: this.parametro.competencia,
        }

        useJwt
          .post('lancamento/GetLoteParcelasHistorico', parametro)
          .then((response) => {
            if (!this.historicoImportacao.length) {
              this.$message.info('Nenhum registro encontrado')
            } else {
              this.historicoImportacao = response.data
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao carregar a lotes')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      visualizar(loteId) {
        this.parametroListaParcelas = {
          loteId: Number(loteId),
        }
        this.$refs['modal-lista-parcelas'].show()
      },
    },
  }
</script>

<style scoped></style>
