<template>
  <b-card-code no-body>
    <b-card-body>
      <!-- Pesquisa -->
      <b-row>
        <b-col md="2">
          <b-form-group label="Mês" label-for="mes">
            <b-form-select id="mes" :options="mesOpcoes" v-model="pesquisar.mes" />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Ano" label-for="ano">
            <v-select-pt name="ano" v-model="pesquisar.exercicio" :options="anoOpcao" label="exercicio"></v-select-pt>
          </b-form-group>
        </b-col>

        <b-col md="5">
          <b-form-group label="Órgão" label-for="orgao">
            <v-select-pt
              name="orgao"
              v-model="pesquisar.orgao"
              :options="orgaoOpcao"
              :reduce="(option) => option.id"
              @option:selected="carregarGrid()"
              label="nome"
              v-on:keydown.enter="carregarGrid"
            >
            </v-select-pt>
          </b-form-group>
        </b-col>

        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Filtrar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="auto">
          <b-dropdown
            :disabled="items.length == 0"
            variant="link"
            no-caret
            toggle-class="p-0"
            left
            :popper-opts="{ positionFixed: true }"
          >
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="margin-top: 21px; width: 160px"
                variant="primary"
                class="btn-icon"
              >
                Exportar
                <feather-icon icon="ChevronDownIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="exportToPDF()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">PDF</span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportarCSV()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">.CSV</span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportarTxt()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">.TXT</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card v-show="this.totalRows === 0">
      <h3 class="text-black text-center mb-2">Não foi encontrado nenhum lançamento para este mês</h3>
    </b-card>

    <span class="m-1"
      >Total de Lançamentos à Regularizar:
      <b-badge pill>
        {{ formatarValor(formatarParaNumber(somatoriaValores)) }}
      </b-badge>
    </span>

    <!-- Tabela -->
    <b-table
      v-show="items.length > 0"
      id="tabelaLancamentos"
      ref="tabelaGrid"
      small
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
      select-mode="multi"
      :selected-variant="'primary'"
      :selected.sync="selectedItems"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <!-- FIX mudar para abrir a modal com os lançamentos -->
          <b-dropdown-item @click="cadTomador(row.item.id)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50">Gerar boleto</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(check)="row">
        <b-form-checkbox
          v-if="row.item.statusDesconto != 'Descontado'"
          v-model="selectedItems"
          @change="adicionaContadorSelecionado(row.item)"
          :value="row.item.id"
          :name="'row-' + row.index"
        ></b-form-checkbox>
      </template>

      <template #cell(nomeCompleto)="row">
        {{ row.item.financeiro.segurado.tomador.nomeCompleto }}
      </template>

      <template #cell(cpf)="row">
        {{ row.item.financeiro.segurado.tomador.cpf }}
      </template>

      <template #cell(dataNascimento)="row">
        {{ formatarData(row.item.dataNascimento) }}
      </template>

      <template #cell(matricula)="row">
        {{ row.item.financeiro.segurado.matricula }}
      </template>

      <template #cell(categoria)="row">
        {{ row.item.financeiro.segurado.situacaoPrevidenciaria }}
      </template>

      <template #cell(valorTotalParcela)="row">
        {{ formatarValor(row.item.valor) }}
      </template>

      <template #cell(descontoRealizado)="row">
        {{ row.item.descontoRealizado ? formatarValor(row.item.descontoRealizado) : formatarValor(0) }}
      </template>

      <template #cell(valorAPagar)="row">
        {{ formatarValor(row.item.valor - row.item.descontoRealizado) }}
      </template>

      <template #cell(contratoNumero)="row">
        {{ row.item.financeiro.codigo }}
      </template>

      <template #cell(periodo)="row">
        {{ String(row.item.competencia).substring(4, 6) + '/' + String(row.item.competencia).substring(0, 4) }}
      </template>

      <template #cell(status)="row">
        <b-badge :variant="statusMap[row.item.statusDesconto][1]">
          {{ statusMap[row.item.statusDesconto][0] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <b-modal
      id="modal-cadastro"
      ref="modal-cadastro"
      centered
      size="lg"
      title="Cadastro do Tomador"
      no-close-on-backdrop
      hide-footer
    >
      <modalCadastroTomador v-if="cadastroTomador" :parametro="cadastroTomador" />
    </b-modal>

    <!-- Contrato -->
    <b-modal id="modal-contrato-consolidado" ref="modal-contrato-consolidado" centered size="xl" hide-header hide-footer>
      <contratoContrato v-if="contrato" :parametro="contrato" />
    </b-modal>

    <!-- Quitar Contrato -->
    <b-modal id="modal-quitar-contrato" ref="modal-quitar-contrato" centered size="xl" hide-header hide-footer>
      <lancamentoQuitacao v-if="contrato" :parametro="contrato" @quitarEvent="quitarMethod" />
    </b-modal>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <!-- Exportação de PDF -->
    <div>
      <RelatorioParcelasARegularizarPaisagem ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
    </div>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import { formatarData, formatarParaNumber, formatarValor } from '@/libs/utils'
  import modalCadastroTomador from '@/views/components/Tomador/modalCadastroTomador.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import lancamentoQuitacao from '../../lancamento/lancamentoQuitacao.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import RelatorioParcelasARegularizarPaisagem from '../relatorios/relatorioParcelasARegularizarPaisagem.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      modalCadastroTomador,
      lancamentoQuitacao,
      ToastificationContent,
      formatarValor,
      formatarData,
      AutenticaSenha,
      formatarParaNumber,
      generateAndDownloadPdf,
      RelatorioParcelasARegularizarPaisagem,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        nomeRelatorio: 'Extrato de Parcelas à Regularizar',
        formatarData,
        formatarValor,
        formatarParaNumber,
        statusValor: undefined,
        statusMap: {
          Descontado: ['Parcela Descontada', 'success'],
          Rejeitada: ['Parcela Rejeitada', 'danger'],
          PagaParcialmente: ['Paga Parcialmente', 'warning'],
          NaoDescontada: ['Não Descontada', 'secondary'],
        },
        statusOpcao: [
          { value: 'Descontado', text: 'Parcelas Descontadas' },
          { value: 'Rejeitada', text: 'Parcelas Rejeitadas' },
          { value: 'PagaParcialmente', text: 'Parcelas Pagas Parcialmente' },
          { value: 'NaoDescontada', text: 'Parcelas Não Descontadas' },
        ],
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        orgaoOpcao: [],
        anoOpcao: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
        ],
        pesquisar: {
          perPage: 10,
          mes: null,
          exercicio: null,
          orgao: null,
          currentPage: 1,
        },
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        mode: '',
        sortDirection: 'asc',
        baseFields: [
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorTotalParcela', label: 'Valor Da Parcela', sortable: true },
          { key: 'descontoRealizado', label: 'Desconto Realizado', sortable: true },
          // { key: "valorAPagar", label: "Valor a Pagar", sortable: true },
          // { key: "periodo", label: "Período", sortable: true },
          { key: 'status', label: 'Status', sortable: true },
        ],
        fields: [
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorAPagar', label: 'Valor a Pagar', sortable: true },
          { key: 'status', label: 'Status', sortable: true },
        ],
        borderless: true,
        statusGRPE: [
          {
            GeradoGRPE: 'success',
            NaoGeradoGRPE: 'warning',
            null: 'warning',
          },
        ],
        statusLancamento: [
          {
            Lancado: 'success',
            null: 'warning',
            NaoLancado: 'warning',
          },
        ],
        items: [],
        finanitems: [],
        lancamento: [],
        parc: [],
        prop: [],
        vouPesquisar: false,
        contrato: null,
        usersUrl: 'https://jsonplaceholder.typicode.com/users',
        photosUrl: 'https://jsonplaceholder.typicode.com/photos',
        acesso: {},
        cadastroTomador: [],
        selectedItems: [],
        habilitarGRPE: false,
        somatoriaValores: 0,
        desabilitarLancGRPE: false,

        // Relatório PDF
        parametroPDF: {
          dados: [],
          colunas: [],
          competencia: '',
          orgaoNome: '',
        },
        itemsPDF: [],
        fieldsPDF: [
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome' },
          { key: 'cpf', label: 'CPF' },
          { key: 'contratoNumero', label: 'Nº Do Contrato' },
          { key: 'valorAPagar', label: 'Valor a Pagar' },
          { key: 'status', label: 'Status' },
        ],
        layoutTxt: {
          cpf: {
            tipo: 'string',
            tamanho: '14',
            preencheComEspaço: true,
            exemplo: '056.336.861-60',
          },
          matricula: {
            tipo: 'string',
            tamanho: '10',
            preencheComEspaço: true,
            exemplo: '0005',
          },
          valorParcela: {
            tipo: 'int',
            tamanho: '16',
            preencheComEspaço: true,
            exemplo: 3585.48,
          },
          parcelaAtual: {
            tipo: 'string',
            tamanho: '7',
            preencheComEspaço: true,
            exemplo: '001/120',
          },
          totalParcela: {
            tipo: 'int',
            tamanho: '3',
            preencheComEspaço: true,
            exemplo: 48,
          },
          nrContrato: {
            tipo: 'string',
            tamanho: '8',
            preencheComEspaço: true,
            exemplo: '005/2023',
          },
          idConsigPrev: {
            tipo: 'int',
            tamanho: '10',
            preencheComEspaço: true,
            exemplo: 2115,
          },
          ano: {
            tipo: 'int',
            tamanho: '4',
            preencheComEspaço: true,
            exemplo: 2023,
          },
          mes: {
            tipo: 'int',
            tamanho: '2',
            preencheComEspaço: true,
            exemplo: 10,
          },
          descontado: {
            tipo: 'boolean',
            tamanho: '5',
            preencheComEspaço: true,
            exemplo: false,
          },
          motivoDoNaoDesconto: {
            tipo: 'string',
            tamanho: '100',
            preencheComEspaço: true,
            exemplo: 'Segurado não possui margem disponível',
          },
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroContratosPendentes = true
        this.acesso.FinanceiroContratosConsolidados = true
      } else {
        this.acesso.FinanceiroContratosPendentes = this.userData.GrupoAcesso.FinanceiroContratosPendentes
        this.acesso.FinanceiroContratosConsolidados = this.userData.GrupoAcesso.FinanceiroContratosConsolidados
      }

      this.carregaAno()
      this.carregarOrgaos()
    },
    methods: {
      async carregarGrid() {
        this.pesquisar.currentPage = 1
        try {
          this.isBusy = true
          const parcelas = await useJwt.post(`lancamento/getParcelasARegularizar`, {
            mes: this.pesquisar.mes,
            ano: this.pesquisar.exercicio,
            orgaoId: this.pesquisar.orgao,
            tomadorNome: this.pesquisar.nome,
            institutoId: this.userData.institutoSelecionado,
          })

          this.items = parcelas.data
          this.totalRows = parcelas.data.length

          this.somatoriaValores = this.items.reduce((acc, item) => {
            return acc + (formatarParaNumber(item.valor) - formatarParaNumber(item.descontoRealizado))
          }, 0)

          this.linhasTabela = this.items
          this.dividirLista(this.linhasTabela, 22)

          let competencia = ''
          let orgaoNome = ''

          if (this.pesquisar.mes) {
            competencia =
              String(this.mesOpcoes.find((item) => item.value == this.pesquisar.mes).text) +
              '/' +
              String(this.pesquisar.exercicio)
          }
          if (this.pesquisar.orgao) {
            orgaoNome = this.orgaoOpcao.find((item) => item.id == this.pesquisar.orgao).nome
          }

          this.itemsPDF = this.items.map((item) => {
            return {
              parcelaAtual: item.parcelaAtual,
              matricula: item.financeiro.segurado.matricula,
              nomeCompleto: item.financeiro.segurado.tomador.nomeCompleto,
              cpf: item.financeiro.segurado.tomador.cpf,
              contratoNumero: item.financeiro.codigo,
              valorAPagar: formatarValor(item.valor - item.descontoRealizado),
              status: item.statusDesconto,
            }
          })

          // Monta variável global de relatório PDF
          this.parametroPDF = {
            dados: this.itemsPDF,
            colunas: this.fieldsPDF,
            institutoNome: this.userData.Instituto.nome,
            competencia,
            orgaoNome,
          }
        } catch (error) {
          console.error('Erro: ' + error)
        }
        this.isBusy = false
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgaoOpcao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregaAno() {
        const mes = moment(new Date(), 'MM').add(1, 'M').format('M')
        const ano = moment(new Date(), 'YYYY').add(1, 'M').format('YYYY')
        this.pesquisar.mes = mes
        this.pesquisar.exercicio = ano
        let anoAtual = 2019
        let listaAnos = []
        for (let i = 0; i < 30; i++) {
          let anoIteracao = String(anoAtual + i)
          listaAnos.push(anoIteracao)
        }

        listaAnos = listaAnos.sort((a, b) => a - b)

        this.anoOpcao = listaAnos
      },
      async realizarEnvioParaSisprev() {
        let listaDescontada = this.items.filter((parcela) => parcela.statusDesconto == 'NaoDescontada')
        let parametroEnvioSisprev = {
          ano: this.pesquisar.exercicio,
          mes: this.pesquisar.mes,
          lista: listaDescontada,
          institutoId: this.userData.institutoSelecionado,
          ambienteApi: this.userData.Instituto.ambienteApi,
        }

        try {
          const retornoEnvio = await useJwt.post('lancamento/RealizaEnvioDescontoParaSisprev', parametroEnvioSisprev)
          this.$message.success('Descontos enviados com sucesso!')
          this.$refs['modal-auth-senha'].hide()
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao enviar descontos para o Sisprev!')
        }
      },
      async realizarBaixaManual() {
        const retorno = (await useJwt.put('lancamento/BaixaLancamentosManualmente', this.selectedItems)).data.mensagem
        this.carregarGrid()
        this.$message.success(retorno)
        this.$refs['modal-auth-senha'].hide()

        this.selectedItems = []
      },
      async adicionaContadorSelecionado() {
        this.somatoriaValores = 0

        this.selectedItems.forEach((item) => {
          let parcela = this.items.find((lancamento) => lancamento.id == item)
          this.somatoriaValores += formatarParaNumber(parcela.valor)
        })
      },
      autenticarMethod(data) {
        if (data) {
          if (this.mode == 'baixa') this.realizarBaixaManual()

          if (this.mode == 'enviaParaSisprev') this.realizarEnvioParaSisprev()
        }
      },
      autenticaEBaixa() {
        this.mode = 'baixa'
        this.$refs['modal-auth-senha'].show()
      },
      confirmaBaixaManual() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }

        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente BAIXAR o(s) lançamento(s) selecionado(s)?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.autenticaEBaixa()
          }
        })
      },
      autenticaEEnviaSisprev() {
        this.mode = 'enviaParaSisprev'
        this.$refs['modal-auth-senha'].show()
      },
      exportToPDF() {
        if (!this.items.length) {
          this.$message.error('Não há dados para exportar!')
          return
        }

        if (this.$refs.tabelaGrid) {
          const htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2PdfLandscape.$el.innerHTML
          const relatorioPaisagem = true
          const nomeInstituto = this.userData.Instituto.nome
          const periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          const nomeRelatorio = this.nomeRelatorio
          const informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      dividirLista(listaOriginal, tamanhoMaximo) {
        let primeiraPagina = listaOriginal.slice(0, tamanhoMaximo)
        const restante = listaOriginal.slice(tamanhoMaximo)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []

        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })

        this.linhasTabela = sublistas
      },
      transformarJson(jsonData) {
        const resultado = []

        jsonData.forEach((item) => {
          const { id, parcelaAtual, valor, competencia, financeiro } = item

          const { segurado } = financeiro

          const { cpf } = segurado.tomador

          resultado.push({
            cpf,
            matricula: segurado.matricula,
            valorParcela: parseFloat(valor.replace(',', '.')),
            parcelaAtual: String(parcelaAtual).padStart(6, '0'),
            totalParcela: parseInt(parcelaAtual.split('/')[1], 10),
            nrContrato: id.toString(),
            idConsigPrev: financeiro.codigo,
            ano: Math.floor(competencia / 100),
            mes: competencia % 100,
            descontado: '',
            motivoDoNaoDesconto: '',
          })
        })

        return resultado
      },
      exportarCSV() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }
        const listaExportacao = this.transformarJson(this.items)
        const arquivoCsv = this.jsonToCsv(listaExportacao)
        this.gerarCsv(arquivoCsv)
      },
      gerarCsv(arquivoCsv) {
        const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(arquivoCsv)
        const link = document.createElement('a')
        link.setAttribute('href', csvData)
        link.setAttribute('download', `${String(this.pesquisar.exercicio) + String(this.pesquisar.mes)}.csv`)
        document.body.appendChild(link)
        link.click()
      },
      jsonToCsv(jsonData) {
        const csvRows = []

        // Cria o cabeçalho CSV com ponto e vírgula como separador
        const headers = Object.keys(jsonData[0])
        csvRows.push(headers.join(';'))

        // Itera sobre os objetos JSON e converte em linhas CSV com ponto e vírgula como separador
        for (const item of jsonData) {
          const values = headers.map((header) => {
            const value = item[header]
            return typeof value === 'string' ? `"${value}"` : value
          })
          csvRows.push(values.join(';'))
        }

        // Combina todas as linhas em uma única string
        const csvString = csvRows.join('\n')

        // Retorna a string CSV
        return csvString
      },
      jsonToTxt(jsonData) {
        const txtRows = []

        jsonData.forEach((item) => {
          const propriedade = Object.keys(item)
          let row = []

          propriedade.forEach((propriedades) => {
            const tamanho = Number(this.layoutTxt[propriedades].tamanho)
            row.push(String(item[propriedades]).padEnd(tamanho))
          })

          row.push('\n')
          txtRows.push(row.join(''))
        })

        return txtRows.join('')
      },
      downloadTxt(jsonData) {
        const txtData = this.jsonToTxt(jsonData)

        const blob = new Blob([txtData], { type: 'text/plain' })
        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = `${String(this.pesquisar.exercicio) + String(this.pesquisar.mes)}.txt`
        document.body.appendChild(link)
        link.click()

        // Libere o objeto Blob e o URL temporário
        window.URL.revokeObjectURL(url)
      },
      exportarTxt() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }

        const listaExportacao = this.transformarJson(this.items)
        this.downloadTxt(listaExportacao)
      },
    },
    watch: {
      statusValor: function (val) {
        if (val == 'Descontado') {
          this.fields = [
            ...this.baseFields.filter((f) => {
              if (f.key == 'valorAPagar') {
                return false
              }
              return true
            }),
          ]
        } else if (val == 'Rejeitada') {
          this.fields = [
            ...this.baseFields.filter((f) => {
              if (f.key == 'valorTotalParcela' || f.key == 'descontoRealizado') {
                return false
              }
              return true
            }),
          ]
        } else if (val == 'PagaParcialmente') {
          this.fields = [...this.baseFields]
        }
      },
    },
  }
</script>

<style lang="scss">
  #tabelaLancamentos > thead > tr > th {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }

  #tabelaLancamentos > tbody > tr > td {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }
</style>
